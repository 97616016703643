import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { setScreen } from "../store/isFullScreenSlice";

const Protected = (props) => {
  const handle = useFullScreenHandle();
  const { Component } = props;
  const { isAuth } = useSelector((state) => state.authenticationSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fullScreen = handle.enter;
  const exitFullScreen = handle.exit;
  const active = handle.active;
  //const [fullscreen, setFullScreen] = useState(false);

  useEffect(() => {
    if (isAuth == false) {
      navigate("/");
    }
    //console.log(fullscreen);
  }, []);

  function allowFullScreen() {
    fullScreen();
    dispatch(setScreen({ isFullScreen: true }));
  }

  function disallowFullScreen() {
    exitFullScreen();
    dispatch(setScreen({ isFullScreen: false }));
  }

  return (
    <>
      <FullScreen handle={handle}>
        <Component fullScreen={allowFullScreen} exitFullScreen={disallowFullScreen} />
      </FullScreen>
      {/* <button onClick={allowFullScreen}>
        Enter fullscreen
      </button> */}
    </>
  );
};

export default Protected;
