import axios from "axios";

const api = axios.create({
  //baseURL: process.env.REACT_APP_DEFAULT_URL,
  baseURL: "https://test.onconavigator.in",
  withCredentials: true,
  headers: {
    "content-type": "application/json",
    Accept: "application/json",
  },
});

export const register = (data) => api.post("/api/v1/users", data);
export const login = (data) => api.post("/api/v1/users/login", data);
export const logout = (data) => api.post("/api/v1/users/logout", data);
export const refresh = (data) => api.get("/api/v1/users/refresh", data);
export const getProfile = (userId) => api.get(`api/v1/users/show-user/${userId}`);
export const forgotPasswordEmail = (data) => api.post(`api/v1/users/forgot-password/`, data);
export const updateEmailPassword = (accessToken, email, data) =>
  api.post(`api/v1/users/change-email-password/${accessToken}/${email}`, data);
export const updatePassword = (userId, data) => api.post(`api/v1/users/change-password/${userId}`, data);
export const submitEnquiry = (data) => api.post("/api/v1/users/send-request", data);
export const submitSurveyData = (data) => api.post("/api/v1/users/send-survey", data);
export const submitPdfRequest = (data) => api.post("/api/v1/users/send-request-for-pdf", data);
export const showLatestArticle = (data) => api.get("/api/v1/users/show-latest-articles", data);
export const blogDetail = (lastItem) => api.get(`api/v1/users/article/${lastItem}`);
export const saveActiveResponseTime = (data) => api.post("/api/v1/tracking/send-tracking-data", data);
export const calculateResult = (calculatorProduct, calculatorCostBasedOn, calculatorCostBasedOnInput) =>
  api.get(`api/v1/calculator/${calculatorProduct}/${calculatorCostBasedOn}/${calculatorCostBasedOnInput}`);
export const bucket = (data) => api.get("/api/v1/users/bucket", data);
export const updateImage = (data) => api.put("/api/v1/users/update-profile/:_id", data);
export const updateProfile = (userId, data) => api.post(`api/v1/users/update-profile/${userId}`, data);
export const showLastVisitedPage = (userId) => api.get(`api/v1/users/show-resume-link/${userId}`);

// Interceptors
api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest && !originalRequest._isRetry) {
      originalRequest.isRetry = true;
      try {
        await axios.get(
          //`${process.env.REACT_APP_DEFAULT_URL}/api/v1/users/refresh`,
          `https://test.onconavigator.in/api/v1/users/refresh`,
          {
            withCredentials: true,
          }
        );

        return api.request(originalRequest);
      } catch (err) {
        console.log(err.message);
      }
    }
    throw error;
  }
);

export default api;
