import React from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import styles from "./Loading.module.css";

function Loading() {
  return (
    <div className={styles.verticalCenter}>
      {/* <img className="my-3" src={loading} alt="loading" /> */}
      <Player
        autoplay
        loop={true}
        //keepLastFrame
        src="/loader.json"
        style={{ height: "20%", width: "20%" }}
      >
        <Controls
          visible={false}
          //buttons={["play", "repeat", "frame", "debug"]}
        />
      </Player>
    </div>
  );
}

export default Loading;
