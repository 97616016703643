import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFullScreen: false,
};

export const isFullScreenSlice = createSlice({
  name: "fullScreen",
  initialState,
  reducers: {
    setScreen: (state, action) => {
      const screen = action.payload;
      console.log(screen);
      state.isFullScreen = screen.isFullScreen;
    },
  },
});

export const { setScreen } = isFullScreenSlice.actions;

export default isFullScreenSlice.reducer;
