import { configureStore } from "@reduxjs/toolkit";
import authenticationSlice from "./authenticationSlice";
import isFullScreenSlice from "./isFullScreenSlice";

export const store = configureStore({
  reducer: {
    authenticationSlice,
    isFullScreenSlice,
  },
});
