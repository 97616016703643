import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLoadingWithRefresh } from "./hooks/useLoadingWithRefresh";

// const urlStyle = window.location.pathname.split("/").slice(-2)[0];

import Loading from "./components/Loading/Loading";
import Protected from "./routes/Protected";

// Tecentiq Pages

const Tecentriq_home = lazy(() =>
  import("./pages/Tecentriq/Tecentriq_home/Tecentriq_home")
);
const Medical_evidence = lazy(() =>
  import("./pages/Tecentriq/Medical_evidence/Medical_evidence")
);
const Impower_150_study_design = lazy(() =>
  import("./pages/Tecentriq/Impower_150_study_design/Impower_150_study_design")
);
const Impower_150_baseline_table = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_150_baseline_table/Impower_150_baseline_table"
  )
);
const Impower_150_overall_itt = lazy(() =>
  import("./pages/Tecentriq/Impower_150_overall_itt/Impower_150_overall_itt")
);
const Impower_150_overall_pd_l1_50 = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_150_overall_pd_l1_50/Impower_150_overall_pd_l1_50"
  )
);
const Impower_150_overall_pd_l1_49 = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_150_overall_pd_l1_49/Impower_150_overall_pd_l1_49"
  )
);
const Impower_150_overall_pd_l1_1 = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_150_overall_pd_l1_1/Impower_150_overall_pd_l1_1"
  )
);
const Impower_150_overall_egfr_tki = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_150_overall_egfr_tki/Impower_150_overall_egfr_tki"
  )
);
const Impower_150_overall_liver_meta = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_150_overall_liver_meta/Impower_150_overall_liver_meta"
  )
);
const Progression_itt = lazy(() =>
  import("./pages/Tecentriq/Progression_itt/Progression_itt")
);
const Progression_pd_l1 = lazy(() =>
  import("./pages/Tecentriq/Progression_pd_l1/Progression_pd_l1")
);
const Progression_egfr_mutation = lazy(() =>
  import(
    "./pages/Tecentriq/Progression_egfr_mutation/Progression_egfr_mutation"
  )
);
const Progression_liver_metastasis = lazy(() =>
  import(
    "./pages/Tecentriq/Progression_liver_metastasis/Progression_liver_metastasis"
  )
);
const Objective_itt = lazy(() =>
  import("./pages/Tecentriq/Objective_itt/Objective_itt")
);
const Objective_pd_l1_subgroups = lazy(() =>
  import(
    "./pages/Tecentriq/Objective_pd_l1_subgroups/Objective_pd_l1_subgroups"
  )
);
const Objective_egfr_liver = lazy(() =>
  import("./pages/Tecentriq/Objective_egfr_liver/Objective_egfr_liver")
);
const Bulky_disease = lazy(() =>
  import("./pages/Tecentriq/Bulky_disease/Bulky_disease")
);
const Brain_metastases = lazy(() =>
  import("./pages/Tecentriq/Brain_metastases/Brain_metastases")
);
const Impower_150_safety = lazy(() =>
  import("./pages/Tecentriq/Impower_150_safety/Impower_150_safety")
);
const Impower_130_study_design = lazy(() =>
  import("./pages/Tecentriq/Impower_130_study_design/Impower_130_study_design")
);
const Impower_130_baseline_table = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_130_baseline_table/Impower_130_baseline_table"
  )
);
const Overall_pd_l1 = lazy(() =>
  import("./pages/Tecentriq/Overall_pd_l1/Overall_pd_l1")
);
const Progression_free_pd_l1_subgroups = lazy(() =>
  import(
    "./pages/Tecentriq/Progression_free_pd_l1_subgroups/Progression_free_pd_l1_subgroups"
  )
);
const Impower_130_safety = lazy(() =>
  import("./pages/Tecentriq/Impower_130_safety/Impower_130_safety")
);
const Impower_110_study_design = lazy(() =>
  import("./pages/Tecentriq/Impower_110_study_design/Impower_110_study_design")
);
const Impower_110_baseline_table = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_110_baseline_table/Impower_110_baseline_table"
  )
);
const High_pd_l1_subgroups = lazy(() =>
  import("./pages/Tecentriq/High_pd_l1_subgroups/High_pd_l1_subgroups")
);
const Median_high_pd_l1_wt = lazy(() =>
  import("./pages/Tecentriq/Median_high_pd_l1_wt/Median_high_pd_l1_wt")
);
const High_pd_l1_sp = lazy(() =>
  import("./pages/Tecentriq/High_pd_l1_sp/High_pd_l1_sp")
);
const Impower_110_safety = lazy(() =>
  import("./pages/Tecentriq/Impower_110_safety/Impower_110_safety")
);
const Impower_oak_study_design = lazy(() =>
  import("./pages/Tecentriq/Impower_oak_study_design/Impower_oak_study_design")
);
const Impower_oak_baseline_table = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_oak_baseline_table/Impower_oak_baseline_table"
  )
);
const Oak_itt = lazy(() => import("./pages/Tecentriq/Oak_itt/Oak_itt"));
const Oak_pd_l1_high = lazy(() =>
  import("./pages/Tecentriq/Oak_pd_l1_high/Oak_pd_l1_high")
);
const Oak_pd_l1_positive = lazy(() =>
  import("./pages/Tecentriq/Oak_pd_l1_positive/Oak_pd_l1_positive")
);
const Oak_pd_l1_negative = lazy(() =>
  import("./pages/Tecentriq/Oak_pd_l1_negative/Oak_pd_l1_negative")
);
const Oak_progression_free_pd_l1_subgroups = lazy(() =>
  import(
    "./pages/Tecentriq/Oak_progression_free_pd_l1_subgroups/Oak_progression_free_pd_l1_subgroups"
  )
);
const Oak_objective_pd_l1_subgroups = lazy(() =>
  import(
    "./pages/Tecentriq/Oak_objective_pd_l1_subgroups/Oak_objective_pd_l1_subgroups"
  )
);
const Oak_safety = lazy(() =>
  import("./pages/Tecentriq/Oak_safety/Oak_safety")
);
const Impower_133_study_design = lazy(() =>
  import("./pages/Tecentriq/Impower_133_study_design/Impower_133_study_design")
);
const Impower_133_baseline_table = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_133_baseline_table/Impower_133_baseline_table"
  )
);
const Impower_133_itt = lazy(() =>
  import("./pages/Tecentriq/Impower_133_itt/Impower_133_itt")
);
const Impower_133_progression_itt = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_133_progression_itt/Impower_133_progression_itt"
  )
);
const Impower_133_safety = lazy(() =>
  import("./pages/Tecentriq/Impower_133_safety/Impower_133_safety")
);
const Alex_study_design = lazy(() =>
  import("./pages/Tecentriq/Alex_study_design/Alex_study_design")
);
const Alex_baseline_table = lazy(() =>
  import("./pages/Tecentriq/Alex_baseline_table/Alex_baseline_table")
);
const Alex_irc = lazy(() => import("./pages/Tecentriq/Alex_irc/Alex_irc"));
const Alex_pfs_itt = lazy(() =>
  import("./pages/Tecentriq/Alex_pfs_itt/Alex_pfs_itt")
);
const Alex_pfs_cns = lazy(() =>
  import("./pages/Tecentriq/Alex_pfs_cns/Alex_pfs_cns")
);
const Alex_pfs_cns_meta = lazy(() =>
  import("./pages/Tecentriq/Alex_pfs_cns_meta/Alex_pfs_cns_meta")
);
const Alex_safety = lazy(() =>
  import("./pages/Tecentriq/Alex_safety/Alex_safety")
);
const Alur_study_design = lazy(() =>
  import("./pages/Tecentriq/Alur_study_design/alur_study_design")
);
const Alur_baseline_table = lazy(() =>
  import("./pages/Tecentriq/Alur_baseline_table/Alur_baseline_table")
);
const Alur_overall = lazy(() =>
  import("./pages/Tecentriq/Alur_overall/Alur_overall")
);
const Alur_pfs_itt = lazy(() =>
  import("./pages/Tecentriq/Alur_pfs_itt/Alur_pfs_itt")
);
const Alur_cummulative_itt = lazy(() =>
  import("./pages/Tecentriq/Alur_cummulative_itt/Alur_cummulative_itt")
);
const Alur_cummulative_6_months = lazy(() =>
  import(
    "./pages/Tecentriq/Alur_cummulative_6_months/Alur_cummulative_6_months"
  )
);
const Alur_safety = lazy(() =>
  import("./pages/Tecentriq/Alur_safety/Alur_safety")
);
const Reference_impower = lazy(() =>
  import("./pages/Tecentriq/Reference_impower/Reference_impower")
);
const Reference_tecentriq = lazy(() =>
  import("./pages/Tecentriq/Reference_tecentriq/Reference_tecentriq")
);
const Io_mono = lazy(() => import("./pages/Tecentriq/Io_mono/Io_mono"));
const Io_chemo = lazy(() => import("./pages/Tecentriq/Io_chemo/Io_chemo"));
const Io_chemo_anti_vegf = lazy(() =>
  import("./pages/Tecentriq/Io_chemo_anti_vegf/Io_chemo_anti_vegf")
);
const Patient_sequencing_egfr = lazy(() =>
  import("./pages/Tecentriq/Patient_sequencing_egfr/Patient_sequencing_egfr")
);
const Relapsed_nsclc = lazy(() =>
  import("./pages/Tecentriq/Relapsed_nsclc/Relapsed_nsclc")
);
const Value_psp = lazy(() => import("./pages/Tecentriq/Value_psp/Value_psp"));
const Psp = lazy(() => import("./pages/Tecentriq/Psp/Psp"));
const Psp_next = lazy(() => import("./pages/Tecentriq/Psp_next/Psp_next"));
const Psp_blue_tree = lazy(() =>
  import("./pages/Tecentriq/Psp_blue_tree/Psp_blue_tree")
);
const Impower_130_objective_itt_wt = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_130_objective_itt_wt/Impower_130_objective_itt_wt"
  )
);
const Impower_110_objective_pd_l1 = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_110_objective_pd_l1/Impower_110_objective_pd_l1"
  )
);
const Oak_objective_itt = lazy(() =>
  import("./pages/Tecentriq/Oak_objective_itt/Oak_objective_itt")
);
const Impower_133_objective_itt = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_133_objective_itt/Impower_133_objective_itt"
  )
);
const Alex_objective_itt = lazy(() =>
  import("./pages/Tecentriq/Alex_objective_itt/Alex_objective_itt")
);
const Alex_objective_itt_cns = lazy(() =>
  import("./pages/Tecentriq/Alex_objective_itt_cns/Alex_objective_itt_cns")
);
const Treatment_guide = lazy(() =>
  import("./pages/Tecentriq/Treatment_guide/Treatment_guide")
);
const Psp_blue_tree_next = lazy(() =>
  import("./pages/Tecentriq/Psp_blue_tree_next/Psp_blue_tree_next")
);
const Objective_iit_cns = lazy(() =>
  import("./pages/Tecentriq/Objective_iit_cns/Objective_iit_cns")
);
// import Decision_support_tool from "./pages/Tecentriq/Decision_support_tool/Decision_support_tool";
const Driver_mutation = lazy(() =>
  import("./pages/Tecentriq/Driver_mutation/Driver_mutation")
);
const Mnsclc = lazy(() => import("./pages/Tecentriq/Mnsclc/Mnsclc"));
const LimitedStage = lazy(() =>
  import("./pages/Tecentriq/Limited_stage/LimitedStage")
);
const EarlyResectable = lazy(() =>
  import("./pages/Tecentriq/Early_stage_resectable/EarlyStageResectable")
);
const LocallyAdvanced = lazy(() =>
  import("./pages/Tecentriq/Locally_advanced/LocallyAdvanced")
);
const Pd_l1_expression = lazy(() =>
  import("./pages/Tecentriq/Pd_l1_expression/Pd_l1_expression")
);
const Pd_l1_50 = lazy(() => import("./pages/Tecentriq/Pd_l1_50/Pd_l1_50"));
const Pd_50_impower_150 = lazy(() =>
  import("./pages/Tecentriq/Pd_50_impower_150/Pd_50_impower_150")
);
const Pd_49_impower_150 = lazy(() =>
  import("./pages/Tecentriq/Pd_49_impower_150/Pd_49_impower_150")
);
const Pd_l11_49 = lazy(() => import("./pages/Tecentriq/Pd_l11_49/Pd_l11_49"));
const Pd_l1_1 = lazy(() => import("./pages/Tecentriq/Pd_l1_1/Pd_l1_1"));
const Pd_1_impower_150 = lazy(() =>
  import("./pages/Tecentriq/Pd_1_impower_150/Pd_1_impower_150")
);
const Impower_130 = lazy(() =>
  import("./pages/Tecentriq/Impower_130/Impower_130")
);
const Impower_130_pd_50 = lazy(() =>
  import("./pages/Tecentriq/Impower_130_pd_50/Impower_130_pd_50")
);
const Impower_130_pd_49 = lazy(() =>
  import("./pages/Tecentriq/Impower_130_pd_49/Impower_130_pd_49")
);
const Impower_130_pd_1 = lazy(() =>
  import("./pages/Tecentriq/Impower_130_pd_1/Impower_130_pd_1")
);
const Special_consideration = lazy(() =>
  import("./pages/Tecentriq/Special_consideration/Special_consideration")
);
const Relapsed = lazy(() => import("./pages/Tecentriq/Relapsed/Relapsed"));
const Oak = lazy(() => import("./pages/Tecentriq/Oak/Oak"));
const Oncogene = lazy(() => import("./pages/Tecentriq/Oncogene/Oncogene"));
//import Driver_mutation from "./pages/Tecentriq/Driver_mutation/Driver_mutation";
const Sclc = lazy(() => import("./pages/Tecentriq/Sclc/Sclc"));
const Extensive_stage = lazy(() =>
  import("./pages/Tecentriq/Extensive_stage/Extensive_stage")
);
const Patient_assistant = lazy(() =>
  import("./pages/Tecentriq/Patient_assistant/Patient_assistant")
);
const Patient_1l_nsclc_next = lazy(() =>
  import("./pages/Tecentriq/Patient_1l_nsclc_next/Patient_1l_nsclc_next")
);
const Patient_l1_nsclc_options = lazy(() =>
  import("./pages/Tecentriq/Patient_l1_nsclc_options/Patient_l1_nsclc_options")
);
const Patient_1l_nsclc = lazy(() =>
  import("./pages/Tecentriq/Patient_1l_nsclc/Patient_1l_nsclc")
);
const Impower_133_overall_survival_baseline = lazy(() =>
  import(
    "./pages/Tecentriq/Impower_133_overall_survival_baseline/Impower_133_overall_survival_baseline"
  )
);
const Patient_1l_alk_nsclc = lazy(() =>
  import("./pages/Tecentriq/Patient_1l_alk_nsclc/Patient_1l_alk_nsclc")
);
const Patient_1l_alk_nsclc_next = lazy(() =>
  import(
    "./pages/Tecentriq/Patient_1l_alk_nsclc_next/Patient_1l_alk_nsclc_next"
  )
);
const Patient_2l_alk_nsclc = lazy(() =>
  import("./pages/Tecentriq/Patient_2l_alk_nsclc/Patient_2l_alk_nsclc")
);
const Patient_2l_alk_nsclc_next = lazy(() =>
  import(
    "./pages/Tecentriq/Patient_2l_alk_nsclc_next/Patient_2l_alk_nsclc_next"
  )
);
const Patient_2l_mnsclc = lazy(() =>
  import("./pages/Tecentriq/Patient_2l_mnsclc/Patient_2l_mnsclc")
);
const Patient_2l_nsclc_next = lazy(() =>
  import("./pages/Tecentriq/Patient_2l_nsclc_next/Patient_2l_nsclc_next")
);
const Patient_2l_nsclc_next_1 = lazy(() =>
  import("./pages/Tecentriq/Patient_2l_nsclc_next_1/Patient_2l_nsclc_next_1")
);
const Patient_es_sclc = lazy(() =>
  import("./pages/Tecentriq/Patient_es_sclc/Patient_es_sclc")
);
const Patient_es_sclc_next = lazy(() =>
  import("./pages/Tecentriq/Patient_es_sclc_next/Patient_es_sclc_next")
);
const Decision_support_tool = lazy(() =>
  import("./components/QuickLinks/Decision_support_tool/Decision_support_tool")
);
const Data_reposittory = lazy(() =>
  import("./components/QuickLinks/Data_reposittory/Data_reposittory")
);
const Physician_patient_interaction = lazy(() =>
  import(
    "./components/QuickLinks/Physician_patient_interaction/Physician_patient_interaction"
  )
);
const ThreeDLung = lazy(() => import("./pages/3D/ThreeDLung"));
const Drawing_tool = lazy(() =>
  import("./pages/Tecentriq/Drawing_tool/Drawing_tool")
);
const Human_body = lazy(() =>
  import("./pages/Tecentriq/Human_body/Human_body")
);
const TecentriqCalculator = lazy(() =>
  import("./pages/Calculator/TecentriqCalculator")
);
// Dashboard
const Login = lazy(() => import("./pages/Users/Authentication/Login/Login"));
const DoctorRegister = lazy(() =>
  import("./pages/Users/Authentication/Register/DoctorRegister")
);
const RocheLogin = lazy(() =>
  import("./pages/Users/Authentication/Roche/RocheLogin")
);
const DashboardLayout = lazy(() => import("./pages/Dashboard/DashboardLayout"));
const MyProfile = lazy(() => import("./pages/Authentication/MyProfile"));
const CaseUpload = lazy(() => import("./pages/CaseUpload/CaseUpload"));
const EditProfile = lazy(() => import("./pages/Authentication/EditProfile"));
const ForgotPassword = lazy(() =>
  import("./pages/Users/Authentication/ForgotPassword")
);
const CreatePassword = lazy(() =>
  import("./pages/Users/Authentication/CreatePassword")
);
const ChangePassword = lazy(() =>
  import("./pages/Authentication/ChangePassword")
);
const Settings = lazy(() => import("./pages/Authentication/Settings"));
const Logout = lazy(() => import("./pages/Authentication/Logout"));
const Activate = lazy(() =>
  import("./pages/Users/Authentication/Activate/Activate")
);
const BlogDetails = lazy(() => import("./components/Blogs/BlogDetails"));
const SurveyLayout = lazy(() => import("./pages/Survey/SurveyLayout"));

function App() {
  //console.log(process.env);
  const { loading } = useLoadingWithRefresh();
  // return loading ? <Loading /> : <h1>Onconavigator</h1>;
  const { user } = useSelector((state) => state.authenticationSlice);
  return loading ? (
    <Loading />
  ) : (
    <>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/activate/:accessToken" element={<Activate />} />

          <Route path="/3d-lung" element={<ThreeDLung />} />
          <Route
            path="/calculator/tecentriq"
            element={<Protected Component={TecentriqCalculator} />}
          />
          <Route
            path="/tecentriq/tecentriq-home"
            element={<Protected Component={Tecentriq_home} />}
          />
          <Route
            path="/tecentriq/medical-evidence"
            element={<Protected Component={Medical_evidence} />}
          />
          <Route
            path="/tecentriq/impower-150-study-design"
            element={<Protected Component={Impower_150_study_design} />}
          />
          <Route
            path="/tecentriq/impower-150-baseline-table"
            element={<Protected Component={Impower_150_baseline_table} />}
          />
          <Route
            path="/tecentriq/impower-150-overall-itt"
            element={<Protected Component={Impower_150_overall_itt} />}
          />
          <Route
            path="/tecentriq/impower-150-overall-pd-l1-50"
            element={<Protected Component={Impower_150_overall_pd_l1_50} />}
          />
          <Route
            path="/tecentriq/impower-150-overall-pd-l1-49"
            element={<Protected Component={Impower_150_overall_pd_l1_49} />}
          />
          <Route
            path="/tecentriq/impower-150-overall-pd-l1"
            element={<Protected Component={Impower_150_overall_pd_l1_1} />}
          />
          <Route
            path="/tecentriq/impower-150-overall-egfr-tki"
            element={<Protected Component={Impower_150_overall_egfr_tki} />}
          />
          <Route
            path="/tecentriq/impower-150-overall-liver-meta"
            element={<Protected Component={Impower_150_overall_liver_meta} />}
          />
          <Route
            path="/tecentriq/progression-itt"
            element={<Protected Component={Progression_itt} />}
          />
          <Route
            path="/tecentriq/progression-pd-l1"
            element={<Protected Component={Progression_pd_l1} />}
          />
          <Route
            path="/tecentriq/progression-egfr-mutation"
            element={<Protected Component={Progression_egfr_mutation} />}
          />
          <Route
            path="/tecentriq/progression-liver-metastasis"
            element={<Protected Component={Progression_liver_metastasis} />}
          />
          <Route
            path="/tecentriq/objective-itt"
            element={<Protected Component={Objective_itt} />}
          />
          <Route
            path="/tecentriq/objective-pd-l1-subgroups"
            element={<Protected Component={Objective_pd_l1_subgroups} />}
          />
          <Route
            path="/tecentriq/objective-egfr-liver"
            element={<Protected Component={Objective_egfr_liver} />}
          />
          <Route
            path="/tecentriq/bulky-disease"
            element={<Protected Component={Bulky_disease} />}
          />
          <Route
            path="/tecentriq/brain-metastases"
            element={<Protected Component={Brain_metastases} />}
          />
          <Route
            path="/tecentriq/impower-150-safety"
            element={<Protected Component={Impower_150_safety} />}
          />
          <Route
            path="/tecentriq/impower-130-study-design"
            element={<Protected Component={Impower_130_study_design} />}
          />
          <Route
            path="/tecentriq/impower-130-baseline-table"
            element={<Protected Component={Impower_130_baseline_table} />}
          />
          <Route
            path="/tecentriq/overall-pd-l1"
            element={<Protected Component={Overall_pd_l1} />}
          />
          <Route
            path="/tecentriq/progression-free-pd-l1-subgroups"
            element={<Protected Component={Progression_free_pd_l1_subgroups} />}
          />
          <Route
            path="/tecentriq/impower-130-safety"
            element={<Protected Component={Impower_130_safety} />}
          />
          <Route
            path="/tecentriq/impower-110-study-design"
            element={<Protected Component={Impower_110_study_design} />}
          />
          <Route
            path="/tecentriq/impower-110-baseline-table"
            element={<Protected Component={Impower_110_baseline_table} />}
          />
          <Route
            path="/tecentriq/high-pd-l1-subgroups"
            element={<Protected Component={High_pd_l1_subgroups} />}
          />
          <Route
            path="/tecentriq/median-high-pd-l1-wt"
            element={<Protected Component={Median_high_pd_l1_wt} />}
          />
          <Route
            path="/tecentriq/high-pd-l1-sp"
            element={<Protected Component={High_pd_l1_sp} />}
          />
          <Route
            path="/tecentriq/impower-110-safety"
            element={<Protected Component={Impower_110_safety} />}
          />
          <Route
            path="/tecentriq/impower-oak-study-design"
            element={<Protected Component={Impower_oak_study_design} />}
          />
          <Route
            path="/tecentriq/impower-oak-baseline-table"
            element={<Protected Component={Impower_oak_baseline_table} />}
          />
          <Route
            path="/tecentriq/oak-itt"
            element={<Protected Component={Oak_itt} />}
          />
          <Route
            path="/tecentriq/oak-pd-l1-high"
            element={<Protected Component={Oak_pd_l1_high} />}
          />
          <Route
            path="/tecentriq/oak-pd-l1-positive"
            element={<Protected Component={Oak_pd_l1_positive} />}
          />
          <Route
            path="/tecentriq/oak-pd-l1-negative"
            element={<Protected Component={Oak_pd_l1_negative} />}
          />
          <Route
            path="/tecentriq/oak-progression-free-pd-l1-subgroups"
            element={
              <Protected Component={Oak_progression_free_pd_l1_subgroups} />
            }
          />
          <Route
            path="/tecentriq/oak-objective-pd-l1-subgroups"
            element={<Protected Component={Oak_objective_pd_l1_subgroups} />}
          />
          <Route
            path="/tecentriq/oak-safety"
            element={<Protected Component={Oak_safety} />}
          />
          <Route
            path="/tecentriq/impower-133-study-design"
            element={<Protected Component={Impower_133_study_design} />}
          />
          <Route
            path="/tecentriq/impower-133-baseline-table"
            element={<Protected Component={Impower_133_baseline_table} />}
          />
          <Route
            path="/tecentriq/impower-133-itt"
            element={<Protected Component={Impower_133_itt} />}
          />
          <Route
            path="/tecentriq/impower-133-progression-itt"
            element={<Protected Component={Impower_133_progression_itt} />}
          />
          <Route
            path="/tecentriq/impower-133-safety"
            element={<Protected Component={Impower_133_safety} />}
          />
          <Route
            path="/tecentriq/alex-study-design"
            element={<Protected Component={Alex_study_design} />}
          />
          <Route
            path="/tecentriq/alex-baseline-table"
            element={<Protected Component={Alex_baseline_table} />}
          />
          <Route
            path="/tecentriq/alex-irc"
            element={<Protected Component={Alex_irc} />}
          />
          <Route
            path="/tecentriq/alex-pfs-itt"
            element={<Protected Component={Alex_pfs_itt} />}
          />
          <Route
            path="/tecentriq/alex-pfs-cns"
            element={<Protected Component={Alex_pfs_cns} />}
          />
          <Route
            path="/tecentriq/alex-pfs-cns-meta"
            element={<Protected Component={Alex_pfs_cns_meta} />}
          />
          <Route
            path="/tecentriq/alex-safety"
            element={<Protected Component={Alex_safety} />}
          />
          <Route
            path="/tecentriq/alur-study-design"
            element={<Protected Component={Alur_study_design} />}
          />
          <Route
            path="/tecentriq/alur-baseline-table"
            element={<Protected Component={Alur_baseline_table} />}
          />
          <Route
            path="/tecentriq/alur-overall"
            element={<Protected Component={Alur_overall} />}
          />
          <Route
            path="/tecentriq/alur-pfs-itt"
            element={<Protected Component={Alur_pfs_itt} />}
          />
          <Route
            path="/tecentriq/alur-cummulative-itt"
            element={<Protected Component={Alur_cummulative_itt} />}
          />
          <Route
            path="/tecentriq/alur-cummulative-6-months"
            element={<Protected Component={Alur_cummulative_6_months} />}
          />
          <Route
            path="/tecentriq/alur-safety"
            element={<Protected Component={Alur_safety} />}
          />
          <Route
            path="/tecentriq/reference-impower"
            element={<Protected Component={Reference_impower} />}
          />
          <Route
            path="/tecentriq/reference-tecentriq"
            element={<Protected Component={Reference_tecentriq} />}
          />
          <Route
            path="/tecentriq/io-mono"
            element={<Protected Component={Io_mono} />}
          />
          <Route
            path="/tecentriq/io-chemo"
            element={<Protected Component={Io_chemo} />}
          />
          <Route
            path="/tecentriq/io-chemo-anti-vegf"
            element={<Protected Component={Io_chemo_anti_vegf} />}
          />
          <Route
            path="/tecentriq/patient-sequencing-egfr"
            element={<Protected Component={Patient_sequencing_egfr} />}
          />
          <Route
            path="/tecentriq/relapsed-nsclc"
            element={<Protected Component={Relapsed_nsclc} />}
          />
          <Route
            path="/tecentriq/value-psp"
            element={<Protected Component={Value_psp} />}
          />
          <Route
            path="/tecentriq/psp"
            element={<Protected Component={Psp} />}
          />
          <Route
            path="/tecentriq/psp-next"
            element={<Protected Component={Psp_next} />}
          />
          <Route
            path="/tecentriq/psp-blue-tree"
            element={<Protected Component={Psp_blue_tree} />}
          />
          <Route
            path="/tecentriq/psp-blue-tree-next"
            element={<Protected Component={Psp_blue_tree_next} />}
          />
          <Route
            path="/tecentriq/impower-130-objective-itt-wt"
            element={<Protected Component={Impower_130_objective_itt_wt} />}
          />
          <Route
            path="/tecentriq/impower-110-objective-pd-l1"
            element={<Protected Component={Impower_110_objective_pd_l1} />}
          />
          <Route
            path="/tecentriq/oak-objective-itt"
            element={<Protected Component={Oak_objective_itt} />}
          />
          <Route
            path="/tecentriq/impower-133-objective-itt"
            element={<Protected Component={Impower_133_objective_itt} />}
          />
          <Route
            path="/tecentriq/alex-objective-itt"
            element={<Protected Component={Alex_objective_itt} />}
          />
          <Route
            path="/tecentriq/alex-objective-itt-cns"
            element={<Protected Component={Alex_objective_itt_cns} />}
          />
          <Route
            path="/tecentriq/treatment-guide"
            element={<Protected Component={Treatment_guide} />}
          />
          <Route
            path="/tecentriq/objective-iit-cns"
            element={<Protected Component={Objective_iit_cns} />}
          />
          <Route
            path="/tecentriq/decision-support-tool"
            element={<Protected Component={Decision_support_tool} />}
          />
          <Route
            path="/tecentriq/driver-mutation"
            element={<Protected Component={Driver_mutation} />}
          />
          <Route
            path="/tecentriq/mnsclc"
            element={<Protected Component={Mnsclc} />}
          />
          <Route
            path="/tecentriq/limited_stage"
            element={<Protected Component={LimitedStage} />}
          />

          <Route
            path="/tecentriq/early-stage-resectable"
            element={<Protected Component={EarlyResectable} />}
          />
          <Route
            path="/tecentriq/locally-advanced"
            element={<Protected Component={LocallyAdvanced} />}
          />
          <Route
            path="/tecentriq/pd-l1-expression"
            element={<Protected Component={Pd_l1_expression} />}
          />
          <Route
            path="/tecentriq/pd-l1-50"
            element={<Protected Component={Pd_l1_50} />}
          />
          <Route
            path="/tecentriq/pd-50-impower-150"
            element={<Protected Component={Pd_50_impower_150} />}
          />
          <Route
            path="/tecentriq/pd-49-impower-150"
            element={<Protected Component={Pd_49_impower_150} />}
          />
          <Route
            path="/tecentriq/pd-l11-49"
            element={<Protected Component={Pd_l11_49} />}
          />
          <Route
            path="/tecentriq/pd-l1-1"
            element={<Protected Component={Pd_l1_1} />}
          />
          <Route
            path="/tecentriq/pd-1-impower-150"
            element={<Protected Component={Pd_1_impower_150} />}
          />
          <Route
            path="/tecentriq/impower-130"
            element={<Protected Component={Impower_130} />}
          />
          <Route
            path="/tecentriq/impower-130-pd-50"
            element={<Protected Component={Impower_130_pd_50} />}
          />
          <Route
            path="/tecentriq/impower-130-pd-49"
            element={<Protected Component={Impower_130_pd_49} />}
          />
          <Route
            path="/tecentriq/impower-130-pd-1"
            element={<Protected Component={Impower_130_pd_1} />}
          />
          <Route
            path="/tecentriq/special-consideration"
            element={<Protected Component={Special_consideration} />}
          />
          <Route
            path="/tecentriq/drawing-tool"
            element={<Protected Component={Drawing_tool} />}
          />
          <Route
            path="/tecentriq/human-body"
            element={<Protected Component={Human_body} />}
          />
          <Route
            path="/tecentriq/relapsed"
            element={<Protected Component={Relapsed} />}
          />
          <Route
            path="/tecentriq/oak"
            element={<Protected Component={Oak} />}
          />
          <Route
            path="/tecentriq/oncogene"
            element={<Protected Component={Oncogene} />}
          />
          <Route
            path="/tecentriq/sclc"
            element={<Protected Component={Sclc} />}
          />
          <Route
            path="/tecentriq/extensive-stage"
            element={<Protected Component={Extensive_stage} />}
          />
          <Route
            path="/tecentriq/patient-assistant"
            element={<Protected Component={Patient_assistant} />}
          />
          <Route
            path="/tecentriq/patient-1l-nsclc-next"
            element={<Protected Component={Patient_1l_nsclc_next} />}
          />
          <Route
            path="/tecentriq/patient-1l-nsclc"
            element={<Protected Component={Patient_1l_nsclc} />}
          />
          <Route
            path="/tecentriq/impower-133-overall-survival-baseline"
            element={
              <Protected Component={Impower_133_overall_survival_baseline} />
            }
          />
          <Route
            path="/tecentriq/patient-l1-nsclc-options"
            element={<Protected Component={Patient_l1_nsclc_options} />}
          />
          <Route
            path="/tecentriq/patient-1l-alk-nsclc"
            element={<Protected Component={Patient_1l_alk_nsclc} />}
          />
          <Route
            path="/tecentriq/patient-1l-alk-nsclc-next"
            element={<Protected Component={Patient_1l_alk_nsclc_next} />}
          />
          <Route
            path="/tecentriq/patient-2l-alk-nsclc"
            element={<Protected Component={Patient_2l_alk_nsclc} />}
          />
          <Route
            path="/tecentriq/patient-2l-alk-nsclc-next"
            element={<Protected Component={Patient_2l_alk_nsclc_next} />}
          />
          <Route
            path="/tecentriq/patient-2l-mnsclc"
            element={<Protected Component={Patient_2l_mnsclc} />}
          />
          <Route
            path="/tecentriq/patient-2l-mnsclc-next"
            element={<Protected Component={Patient_2l_nsclc_next} />}
          />
          <Route
            path="/tecentriq/patient-2l-nsclc-next-1"
            element={<Protected Component={Patient_2l_nsclc_next_1} />}
          />
          <Route
            path="/tecentriq/patient-es-sclc"
            element={<Protected Component={Patient_es_sclc} />}
          />
          <Route
            path="/tecentriq/patient-es-sclc-next"
            element={<Protected Component={Patient_es_sclc_next} />}
          />
          {/* Dashboard */}
          <Route
            path="/dashboard/decision-support-tool"
            element={<Protected Component={Decision_support_tool} />}
          />
          <Route
            path="/dashboard/data-repository"
            element={<Protected Component={Data_reposittory} />}
          />
          <Route
            path="/dashboard/physician-patient-interaction"
            element={<Protected Component={Physician_patient_interaction} />}
          />
          <Route
            path="/dashboard"
            element={<Protected Component={DashboardLayout} />}
          />
          <Route
            path="/profile"
            element={<Protected Component={MyProfile} />}
          />
          <Route
            path="/case-upload"
            element={<Protected Component={CaseUpload} />}
          />
          <Route
            path="/edit-profile"
            element={<Protected Component={EditProfile} />}
          />
          <Route
            path="/change-password"
            element={<Protected Component={ChangePassword} />}
          />
          <Route path="/forgot-password/" element={<ForgotPassword />} />
          <Route
            path="/create-password/:accessToken/:email"
            element={<CreatePassword />}
          />
          <Route path="/logout" element={<Protected Component={Logout} />} />
          <Route
            path="/settings"
            element={<Protected Component={Settings} />}
          />
          <Route
            path="/article/:slug"
            element={<Protected Component={BlogDetails} />}
          />
          <Route path="/" element={<Protected Component={Login} />} />
          <Route
            path="/survey"
            element={<Protected Component={SurveyLayout} />}
          />
          {/* Open Routes */}
          <Route path="/register" element={<DoctorRegister />} />
          <Route path="/auth/roche-login" element={<RocheLogin />} />
        </Routes>
      </Suspense>
    </>
  );
  //return <Loading />;
}

export default App;
